<template>
	<div class="apaympro-container">
		<!-- Color Top Bar -->
		<div class="apaympro-color-bar"></div>

		<!-- Loader -->
		<loading :active.sync="isLoading" :can-cancel="false" :on-cancel="onCancel" :is-full-page="fullPage" loader="bars"
			:opacity="1"></loading>

		<!-- Form Receipt -->
		<div class="apaympro-wrapper animated fadeIn" :hidden="hideReceipt">
			<div id="payment-page-pay" class="apaympro-page" style="margin-top: 2%">
				<div style="
            display: flex;
            align-items: center;
            justify-content: space-between;
          " class="mb-3">
					<!-- Gauche -->
					<div style="position: absolute; z-index: 12">
						<div>
							<!-- Whatsapp -->
							<ShareNetwork network="WhatsApp"
								:url="'https://pay.apaym.com/recu/' + url_data.ref + '#'.trim()" :title="'Voilà mon reçu !'"
								:description="'Téléchargez votre reçu pour le paiement effectué via Visa QR, Cartes Bancaires, Mobile Money, USSD APaym ou APaym chez le marchand ' +
									dataPage.merchant +
									' le ' +
									dataPage.date
									" quote="Le moyen de paiement marchand le plus simplifié et pratique" hashtags="APaymPro,PaieMoi,Marchand"
								class="btn btn-link btn-lg btn-whatsapp" style="padding: 12px !important">
								<i style="font-size: 22px" class="fab fa-whatsapp"></i>
							</ShareNetwork>
							<!--Email -->
							<ShareNetwork network="Email" url="" :title="'Reçu de paiement APAYM Pay - ' +
								dataPage.merchant +
								' (Ref: ' +
								url_data.ref +
								'#' +
								')'
								" :description="'Trouvez via le lien ci-dessous le reçu de paiement APAYM Pay - Ref: ' +
		url_data.ref +
		'#' +
		'\n\n' +
		'https://pay.apaym.com/recu/' +
		url_data.ref +
		'\n\n' +
		'Paiement effectué via chez le marchand ' +
		dataPage.merchant +
		' le ' +
		dataPage.date
		" class="btn btn-link btn-lg btn-gmail" style="padding: 12px !important">
								<i style="font-size: 22px" class="fa fa-envelope"></i>
							</ShareNetwork>
							<!-- Telegram -->
							<ShareNetwork network="telegram"
								:url="'https://pay.apaym.com/recu/' + url_data.ref + '#'.trim()" :title="'Voilà mon reçu !'"
								:description="'Téléchargez votre reçu pour le paiement effectué via Visa QR, Cartes Bancaires, Mobile Money, USSD APaym ou APaym chez le marchand ' +
									dataPage.merchant +
									' le ' +
									dataPage.date
									" quote="Le moyen de paiement marchand le plus simplifié et pratique" hashtags="APaymPro,PaieMoi,Marchand"
								class="btn btn-link btn-lg btn-telegram" style="padding: 12px !important">
								<i style="font-size: 22px" class="fab fa-telegram"></i>
							</ShareNetwork>
						</div>
					</div>
					<!-- Gauche -->
					<!-- Milieu -->
					<div class="container">
						<a :href="'https://pay.apaym.com/' + dataPage.slug" class="btn btn-link btn-block"
							style="font-weight: 800; color: #0e2abd">{{ $t("receipt.button2.value") }}</a>
					</div>
					<!-- Milieu -->

					<!-- Droit -->
					<!-- Choose language -->
					<div style="position: absolute; right: 10px; z-index: 12">
						<select class="element-is-select" @change="changeLanguage($event)" v-model="$i18n.locale"
							style="border: none; background: transparent">
							<option v-for="(lang, i) in langs" :key="`lang-${i}`" :value="lang">
								{{
									lang == "fr"
									? "🇫🇷 Français"
									: lang == "en"
										? "🇬🇧 English"
										: "🇨🇮 Malinké"
								}}
							</option>
						</select>
					</div>
					<!-- Choose language -->
					<!-- Droit -->
				</div>

				<div class="body">
					<img src="../assets/recu.png" alt="Logo zigzag"
						style="transform: rotate(180deg);position: relative;top: 6px;z-index: 10;" />

					<div class="payment-success container animated fadeIn text-center"
						style="box-shadow: 0px 10px 40px 10px hsl(229deg 6% 66%);border: none !important;">
						<div class="apaympro-header mt-1 mb-2" style="margin-bottom: 0">
							<div class="company-logo" v-if="dataPage.businessLogo" :style="dataPage.type == 'SP' || dataPage.type == 'ENTREPRENEUR'
								? 'width: 80px !important; margin-top: -35px'
								: 'width: 130px !important; margin-top: -35px'
								">
								<img id="company-logo-img" :src="dataPage.businessLogo" alt="Business Logo" />
							</div>
						</div>

						<div ref="document" class="container text-center">
							<div class="apaympro-header mt-1 mb-2 pb-2"
								style="border-bottom: 1px dashed;margin-bottom: 0;height: auto;">
								<h3 style="margin: 0;font-weight: 900;text-transform: uppercase;font-size: 1.5rem;">
									{{ dataPage.merchant }}
								</h3>

								<p class="company-name text-dark" v-if="dataPage.type == 'SP'">
									{{ $t("text_header.by.value") }}
									<span style="font-weight: 600">{{ dataPage.nom }}</span>
									<br />
									<span class="font-12" style="text-transform: none">{{
										$t("text_header.enterprising.value")
									}}</span>
								</p>
								<p class="company-name" v-if="dataPage.type !== 'SP'">
									<span style="font-weight: 600">
										{{ dataPage.type }}
										{{
											dataPage.rcc !== "0" ||
											dataPage.rcc.includes("---") == "-1"
											? dataPage.rcc !== ""
												? "- " + dataPage.rcc
												: ""
											: ""
										}}
									</span>
								</p>
							</div>
							<strong>
								{{ $t("receipt.transaction.value") }}
							</strong>
							<br />
							<span class="font-14 text-secondary" style="font-weight: 900">
								{{ dataPage.date | formatDate }}
							</span>

							<img src="../assets/Tampons-Apaym.png" alt="Logo tampon"
								style="position: absolute; top: 38%; left: 55%" width="230" />

							<!--************-->
							<!--************-->
							<!-- Air France -->
							<!--************-->
							<!--************-->
							<div style="
                  border-top: 1px dashed;
                  display: flex;
                  flex-direction: column;
                  border-bottom: 1px dashed;
                  align-items: flex-start;
                  line-height: 1.4rem;
                " class="mt-2" v-if="dataPage.air_france == 1">
								<span class="font-13 mt-2 text-secondary" style="font-weight: 600">
									{{ $t("receipt.client_air_france.value") }}
								</span>

								<span class="font-18 mb-2" style="font-weight: 900">
									{{ dataPage.client }}
								</span>

								<span class="font-13 mt-2 text-secondary" style="font-weight: 600" v-if="dataPage.contactClient !== '' &&
									dataPage.contactClient !== 'Numero inconnu'
									">
									{{ $t("text_form.number_air_france.value") }}
								</span>

								<span class="font-18 mb-2" style="font-weight: 900; text-align: left" v-if="dataPage.contactClient !== '' &&
									dataPage.contactClient !== 'Numero inconnu'
									">
									{{ dataPage.contactClient }}
									<br />
									<div class="font-14 mt-2" style="font-weight: 500; text-align: left"
										v-if="nombre_transactions !== ''">
										<img :src="require(`../assets/img/badges/${imgStatus}`)" width="20"
											style="margin-top: -5px" />
										Client APaym {{ statusClient }}
									</div>
								</span>

								<span class="font-13 mt-2 text-secondary" style="font-weight: 600">
									{{ $t("receipt.method.value") }}
								</span>
								<span class="font-18 mb-2" style="font-weight: 900; text-align: left">
									{{ dataPage.mode_pay }}
								</span>

								<span class="font-13 mt-2 text-secondary" style="font-weight: 600">
									{{ $t("receipt.amount.value") }}
								</span>
								<h2 class="mb-2" style="font-weight: 900; margin: 0; text-align: left">
									{{
										new Intl.NumberFormat("fr-FR", {
											style: "currency",
											currency: "XOF",
										}).format(dataPage.amount)
									}}
								</h2>

								<span v-if="dataPage.fees" class="font-13 text-secondary" style="font-weight: 600">
									{{ $t("text_fees.value") }}
								</span>
								<span v-if="dataPage.fees" class="font-18 mb-2" style="font-weight: 900; text-align: left">
									{{
										new Intl.NumberFormat("fr-FR", {
											style: "currency",
											currency: "XOF",
										}).format(dataPage.fees)
									}}
								</span>

								<span v-if="dataPage.numero_compte" class="font-13 mt-2 text-secondary"
									style="font-weight: 600">
									{{ $t("text_form.pnr_air_france.value") }}
								</span>
								<span v-if="dataPage.numero_compte" class="font-18 mb-2"
									style="font-weight: 900; text-align: left">
									{{ dataPage.numero_compte }}
								</span>

								<span v-if="dataPage.service" class="font-13 mt-2 text-secondary" style="font-weight: 600">
									{{ $t("text_form.transaction_nature2.value") }}
								</span>
								<span v-if="dataPage.service" class="font-18 mb-2"
									style="font-weight: 900; text-align: left">
									{{ dataPage.service }}
								</span>

								<span v-if="dataPage.immatriculation" class="font-13 mt-2 text-secondary"
									style="font-weight: 600">
									{{ $t("text_form.flying_number_air_france.value") }}
								</span>
								<span v-if="dataPage.immatriculation" class="font-18 mb-2"
									style="font-weight: 900; text-align: left">
									{{ dataPage.immatriculation }}
								</span>

								<span v-if="dataPage.motif" class="font-13 mt-2 text-secondary" style="font-weight: 600">
									{{ $t("receipt.reason.value") }}
								</span>
								<span v-if="dataPage.motif" class="font-18 mb-2" style="font-weight: 900; text-align: left">
									{{ dataPage.motif }}
								</span>

								<span class="font-13 mt-2 text-secondary" style="font-weight: 600">
									{{ $t("receipt.ref.value") }}
								</span>
								<span class="font-18 mb-2" style="font-weight: 900; text-align: left">
									{{ dataPage.reference }}
								</span>
							</div>
							<!--************-->
							<!--************-->
							<!-- Air France -->
							<!--************-->
							<!--************-->

							<!--************-->
							<!--************-->
							<!-- Damac Immo -->
							<!--************-->
							<!--************-->
							<div style="
                  border-top: 1px dashed;
                  display: flex;
                  flex-direction: column;
                  border-bottom: 1px dashed;
                  align-items: flex-start;
                  line-height: 1.4rem;
                " class="mt-2" v-if="dataPage.air_france == 1">
								<!-- Autres - Societe -->
								<div style="
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                  ">
									<span v-if="dataPage.societe" class="font-13 mt-2 text-secondary"
										style="font-weight: 600">
										{{ $t("receipt.society.value") }}
									</span>
									<span v-if="dataPage.societe" class="font-18 mb-2" style="font-weight: 900">
										{{ dataPage.societe }}
									</span>
								</div>
								<!-- Autres - Societe -->

								<span class="font-13 mt-2 text-secondary" style="font-weight: 600">
									{{ $t("receipt.client.value") }}
								</span>

								<span class="font-18 mb-2" style="font-weight: 900">
									{{ dataPage.client }}
								</span>

								<span class="font-13 mt-2 text-secondary" style="font-weight: 600" v-if="dataPage.contactClient !== '' &&
									dataPage.contactClient !== 'Numero inconnu'
									">
									{{ $t("receipt.contact.value") }}
								</span>

								<span class="font-18 mb-2" style="font-weight: 900; text-align: left" v-if="dataPage.contactClient !== '' &&
									dataPage.contactClient !== 'Numero inconnu'
									">
									{{ dataPage.contactClient }}
									<br />
									<div class="font-14 mt-2" style="font-weight: 500; text-align: left"
										v-if="nombre_transactions !== ''">
										<img :src="require(`../assets/img/badges/${imgStatus}`)" width="20"
											style="margin-top: -5px" />
										Client APaym {{ statusClient }}
									</div>
								</span>

								<span v-if="dataPage.contactClient2 &&
									dataPage.contactClient2 !== 'Numero inconnu'
									" class="font-13 mt-2 text-secondary" style="font-weight: 600">
									{{ $t("receipt.contact.value") }}
								</span>

								<span class="font-18 mb-2" style="font-weight: 900; text-align: left"
									v-if="dataPage.contactClient2 !== 'Numero inconnu'">
									{{ dataPage.contactClient2 }}
								</span>

								<span class="font-13 mt-2 text-secondary" style="font-weight: 600">
									{{ $t("receipt.method.value") }}
								</span>
								<span class="font-18 mb-2" style="font-weight: 900; text-align: left">
									{{ dataPage.mode_pay }}
								</span>

								<span class="font-13 mt-2 text-secondary" style="font-weight: 600">
									{{ $t("receipt.amount.value") }}
								</span>
								<h2 class="mb-2" style="font-weight: 900; margin: 0; text-align: left">
									{{
										new Intl.NumberFormat("fr-FR", {
											style: "currency",
											currency: "XOF",
										}).format(dataPage.amount)
									}}
								</h2>

								<span v-if="dataPage.fees" class="font-13 text-secondary" style="font-weight: 600">
									{{ $t("text_fees.value") }}
								</span>
								<span v-if="dataPage.fees" class="font-18 mb-2" style="font-weight: 900; text-align: left">
									{{
										new Intl.NumberFormat("fr-FR", {
											style: "currency",
											currency: "XOF",
										}).format(dataPage.fees)
									}}
								</span>

								<span v-if="dataPage.motif" class="font-13 mt-2 text-secondary" style="font-weight: 600">
									{{ $t("text_form.apartment_number.value") }}
								</span>
								<span v-if="dataPage.motif" class="font-18 mb-2" style="font-weight: 900; text-align: left">
									{{ dataPage.motif }}
								</span>

								<span class="font-13 mt-2 text-secondary" style="font-weight: 600">
									{{ $t("receipt.ref.value") }}
								</span>
								<span class="font-18 mb-2" style="font-weight: 900; text-align: left">
									{{ dataPage.reference }}
								</span>
							</div>
							<!--************-->
							<!--************-->
							<!-- Damac Immo -->
							<!--************-->
							<!--************-->

							<!--******************-->
							<!--******************-->
							<!-- Baie des sirenes -->
							<!--******************-->
							<!--******************-->
							<div style="
                  border-top: 1px dashed;
                  display: flex;
                  flex-direction: column;
                  border-bottom: 1px dashed;
                  align-items: flex-start;
                  line-height: 1.4rem;
                " class="mt-2" v-if="dataPage.baie_sirenes == 1">
								<span class="font-13 mt-2 text-secondary" style="font-weight: 600">
									{{ $t("receipt.client.value") }}
								</span>

								<span class="font-18 mb-2" style="font-weight: 900">
									{{ dataPage.client }}
								</span>

								<span class="font-13 mt-2 text-secondary" style="font-weight: 600" v-if="dataPage.contactClient !== '' &&
									dataPage.contactClient !== 'Numero inconnu'
									">
									{{ $t("receipt.contact.value") }}
								</span>

								<span class="font-18 mb-2" style="font-weight: 900; text-align: left" v-if="dataPage.contactClient !== '' &&
									dataPage.contactClient !== 'Numero inconnu'
									">
									{{ dataPage.contactClient }}
									<br />
									<div class="font-14 mt-2" style="font-weight: 500; text-align: left"
										v-if="nombre_transactions !== ''">
										<img :src="require(`../assets/img/badges/${imgStatus}`)" width="20"
											style="margin-top: -5px" />
										Client APaym {{ statusClient }}
									</div>
								</span>

								<span class="font-13 mt-2 text-secondary" style="font-weight: 600">
									{{ $t("receipt.method.value") }}
								</span>
								<span class="font-18 mb-2" style="font-weight: 900; text-align: left">
									{{ dataPage.mode_pay }}
								</span>

								<span class="font-13 mt-2 text-secondary" style="font-weight: 600">
									{{ $t("receipt.amount.value") }}
								</span>
								<h2 class="mb-2" style="font-weight: 900; margin: 0; text-align: left">
									{{
										new Intl.NumberFormat("fr-FR", {
											style: "currency",
											currency: "XOF",
										}).format(dataPage.amount)
									}}
								</h2>

								<span v-if="dataPage.fees" class="font-13 text-secondary" style="font-weight: 600">
									{{ $t("text_fees.value") }}
								</span>
								<span v-if="dataPage.fees" class="font-18 mb-2" style="font-weight: 900; text-align: left">
									{{
										new Intl.NumberFormat("fr-FR", {
											style: "currency",
											currency: "XOF",
										}).format(dataPage.fees)
									}}
								</span>

								<span v-if="dataPage.motif" class="font-13 mt-2 text-secondary" style="font-weight: 600">
									{{ $t("text_form.hotel_reservation_number.value") }}
								</span>
								<span v-if="dataPage.motif" class="font-18 mb-2" style="font-weight: 900; text-align: left">
									{{ dataPage.motif }}
								</span>

								<span v-if="dataPage.societe" class="font-13 mt-2 text-secondary" style="font-weight: 600">
									{{ $t("text_form.remark.value") }}
								</span>
								<span v-if="dataPage.societe" class="font-18 mb-2" style="font-weight: 900">
									{{ dataPage.societe }}
								</span>

								<span class="font-13 mt-2 text-secondary" style="font-weight: 600">
									{{ $t("receipt.ref.value") }}
								</span>
								<span class="font-18 mb-2" style="font-weight: 900; text-align: left">
									{{ dataPage.reference }}
								</span>
							</div>
							<!--******************-->
							<!--******************-->
							<!-- Baie des sirenes -->
							<!--******************-->
							<!--******************-->

							<!--******************-->
							<!--******************-->
							<!-- NSIA ASSURANCE -->
							<!--******************-->
							<!--******************-->
							<div style="border-top: 1px dashed;display: flex;flex-direction: column;border-bottom: 1px dashed;align-items: flex-start;line-height: 1.4rem;"
								class="mt-2" v-if="dataPage.merchantID === '24111'">
								<span class="font-13 mt-2 text-secondary" style="font-weight: 600">
									{{ $t("receipt.client.value") }}
								</span>

								<span class="font-18 mb-2 text-left" style="font-weight: 900">
									{{ dataPage.client }}
								</span>

								<span class="font-13 mt-2 text-secondary" style="font-weight: 600" v-if="dataPage.contactClient !== '' &&
									dataPage.contactClient !== 'Numero inconnu'
									">
									{{ $t("receipt.contact.value") }}
								</span>

								<span class="font-18 mb-2" style="font-weight: 900; text-align: left" v-if="dataPage.contactClient !== '' &&
									dataPage.contactClient !== 'Numero inconnu'
									">
									{{ dataPage.contactClient }}
									<br />
									<div class="font-14 mt-2" style="font-weight: 500; text-align: left"
										v-if="nombre_transactions !== ''">
										<img :src="require(`../assets/img/badges/${imgStatus}`)" width="20"
											style="margin-top: -5px" />
										Client APaym {{ statusClient }}
									</div>
								</span>

								<span class="font-13 mt-2 text-secondary" style="font-weight: 600">
									{{ $t("receipt.method.value") }}
								</span>
								<span class="font-18 mb-2" style="font-weight: 900; text-align: left">
									{{ dataPage.mode_pay }}
								</span>

								<span class="font-13 mt-2 text-secondary" style="font-weight: 600">
									{{ $t("receipt.amount.value") }}
								</span>
								<h2 class="mb-2" style="font-weight: 900; margin: 0; text-align: left">
									{{
										new Intl.NumberFormat("fr-FR", {
											style: "currency",
											currency: "XOF",
										}).format(dataPage.amount)
									}}
								</h2>

								<span v-if="dataPage.fees" class="font-13 text-secondary" style="font-weight: 600">
									{{ $t("text_fees.value") }}
								</span>
								<span v-if="dataPage.fees" class="font-18 mb-2" style="font-weight: 900; text-align: left">
									{{
										new Intl.NumberFormat("fr-FR", {
											style: "currency",
											currency: "XOF",
										}).format(dataPage.fees)
									}}
								</span>

								<span v-if="dataPage.numero_police" class="font-13 mt-2 text-secondary"
									style="font-weight: 600">
									{{ $t("receipt.numero_police.value") }}
								</span>
								<span v-if="dataPage.numero_police" class="font-18 mb-2"
									style="font-weight: 900; text-align: left">
									{{ dataPage.numero_police }}
								</span>

								<span v-if="dataPage.numero_quittance" class="font-13 mt-2 text-secondary"
									style="font-weight: 600">
									{{ $t("receipt.numero_quittance.value") }}
								</span>
								<span v-if="dataPage.numero_quittance" class="font-18 mb-2"
									style="font-weight: 900; text-align: left">
									{{ dataPage.numero_quittance }}
								</span>

								<span v-if="dataPage.motif" class="font-13 mt-2 text-secondary" style="font-weight: 600">
									{{ $t("receipt.reason.value") }}
								</span>
								<span v-if="dataPage.motif" class="font-18 mb-2" style="font-weight: 900; text-align: left">
									{{ dataPage.motif }}
								</span>

								<span v-if="dataPage.immatriculation" class="font-13 mt-2 text-secondary"
									style="font-weight: 600">
									{{ $t("receipt.registration.value") }}
								</span>
								<span v-if="dataPage.immatriculation" class="font-18 mb-2"
									style="font-weight: 900; text-align: left">
									{{ dataPage.immatriculation }}
								</span>

								<span v-if="dataPage.societe" class="font-13 mt-2 text-secondary" style="font-weight: 600">
									{{ $t("text_form.remark.value") }}
								</span>
								<span v-if="dataPage.societe" class="font-18 mb-2" style="font-weight: 900">
									{{ dataPage.societe }}
								</span>


								<div v-if="dataPage.motif === 'Assurance Auto'">

									<!-- Numero de tirage -->
									<span v-if="dataPage.numeroTirage && dataPage.amount >= 75000"
										class="font-13 mt-2 text-secondary" style="font-weight: 600">
										{{ $i18n.locale === "fr" ? "Numéro de tirage" : "Draw number" }}
									</span>
									<span v-if="dataPage.numeroTirage && dataPage.amount >= 75000" class="font-18 mb-2"
										style="font-weight: 900">
										{{ dataPage.numeroTirage }}
									</span>
									<!-- Numero de tirage -->

									<!-- Nombre de chances -->
									<span v-if="dataPage.nbreChances && dataPage.amount >= 75000"
										class="font-13 mt-2 text-secondary" style="font-weight: 600">
										{{ $i18n.locale === "fr" ? "Nombre de chances" : "Number of chances" }}
									</span>
									<span v-if="dataPage.nbreChances && dataPage.amount >= 75000" class="font-18 mb-2"
										style="font-weight: 900">
										{{ dataPage.nbreChances }}
									</span>
									<!-- Nombre de chances -->

								</div>

								<div v-if="dataPage.motif === 'Assurance IA'">

									<!-- Numero de tirage -->
									<span v-if="dataPage.numeroTirage && dataPage.amount >= 7000"
										class="font-13 mt-2 text-secondary" style="font-weight: 600">
										{{ $i18n.locale === "fr" ? "Numéro de tirage" : "Draw number" }}
									</span>
									<span v-if="dataPage.numeroTirage && dataPage.amount >= 7000" class="font-18 mb-2"
										style="font-weight: 900">
										{{ dataPage.numeroTirage }}
									</span>
									<!-- Numero de tirage -->

									<!-- Nombre de chances -->
									<span v-if="dataPage.nbreChances && dataPage.amount >= 7000"
										class="font-13 mt-2 text-secondary" style="font-weight: 600">
										{{ $i18n.locale === "fr" ? "Nombre de chances" : "Number of chances" }}
									</span>
									<span v-if="dataPage.nbreChances && dataPage.amount >= 7000" class="font-18 mb-2"
										style="font-weight: 900">
										{{ dataPage.nbreChances }}
									</span>
									<!-- Nombre de chances -->

								</div>

								<span class="font-13 mt-2 text-secondary" style="font-weight: 600">
									{{ $t("receipt.ref.value") }}
								</span>
								<span class="font-18 mb-2" style="font-weight: 900; text-align: left">
									{{ dataPage.reference }}
								</span>
							</div>
							<!--******************-->
							<!--******************-->
							<!-- NSIA ASSURANCE -->
							<!--******************-->
							<!--******************-->


							<!--******************-->
							<!--******************-->
							<!-- ZINO CIGARES EL. -->
							<!--******************-->
							<!--******************-->
							<div style="
                                  border-top: 1px dashed;
                                  display: flex;
                                  flex-direction: column;
                                  border-bottom: 1px dashed;
                                  align-items: flex-start;
                                  line-height: 1.4rem;
                                " class="mt-2" v-if="dataPage.merchantID == '24714'">
								<span class="font-13 mt-2 text-secondary" style="font-weight: 600">
									{{ $t("receipt.client.value") }}
								</span>

								<span class="font-18 mb-2" style="font-weight: 900">
									{{ dataPage.client }}
								</span>

								<span class="font-13 mt-2 text-secondary" style="font-weight: 600" v-if="dataPage.contactClient !== '' &&
									dataPage.contactClient !== 'Numero inconnu'
									">
									{{ $t("receipt.contact.value") }}
								</span>

								<span class="font-18 mb-2" style="font-weight: 900; text-align: left" v-if="dataPage.contactClient !== '' &&
									dataPage.contactClient !== 'Numero inconnu'
									">
									{{ dataPage.contactClient }}
									<br />
									<div class="font-14 mt-2" style="font-weight: 500; text-align: left"
										v-if="nombre_transactions !== ''">
										<img :src="require(`../assets/img/badges/${imgStatus}`)" width="20"
											style="margin-top: -5px" />
										Client APaym {{ statusClient }}
									</div>
								</span>

								<span class="font-13 mt-2 text-secondary" style="font-weight: 600">
									{{ $t("text_form.email.value") }}
								</span>
								<span class="font-18 mb-2" style="font-weight: 900; text-align: left">
									{{ dataPage.email }}
								</span>

								<span class="font-13 mt-2 text-secondary" style="font-weight: 600">
									{{ $t("receipt.method.value") }}
								</span>
								<span class="font-18 mb-2" style="font-weight: 900; text-align: left">
									{{ dataPage.mode_pay }}
								</span>

								<span class="font-13 mt-2 text-secondary" style="font-weight: 600">
									{{ $t("receipt.amount.value") }}
								</span>
								<h2 class="mb-2" style="font-weight: 900; margin: 0; text-align: left">
									{{
										new Intl.NumberFormat("fr-FR", {
											style: "currency",
											currency: "XOF",
										}).format(dataPage.amount)
									}}
								</h2>

								<span v-if="dataPage.quantite" class="font-13 mt-2 text-secondary" style="font-weight: 600">
									{{ $t("text_form.box_quantity.value") }}
								</span>
								<span v-if="dataPage.quantite" class="font-18 mb-2"
									style="font-weight: 900; text-align: left">
									{{
										dataPage.quantite
									}} {{
	$i18n.locale == 'fr' ? (parseInt(dataPage.quantite) > 1 ? 'boîtes' : 'boîte') :
	(parseInt(dataPage.quantite) > 1 ? 'boxes' : 'box')
}}
								</span>

								<span v-if="dataPage.adresse" class="font-13 mt-2 text-secondary" style="font-weight: 600">
									{{ $t("text_form.delivery_address.value") }}
								</span>
								<span v-if="dataPage.adresse" class="font-18 mb-2"
									style="font-weight: 900; text-align: left">
									{{ dataPage.adresse }}
								</span>

								<span v-if="dataPage.vip" class="font-13 mt-2 text-secondary" style="font-weight: 600">
									{{ $t("text_form.client_vip.value") }}
								</span>
								<span v-if="dataPage.vip" class="font-18 mb-2" style="font-weight: 900; text-align: left">
									{{ $i18n.locale === 'fr' ? dataPage.vip : (dataPage.vip === 'Non' ? 'No' : 'Yes') }}
								</span>

								<span class="font-13 mt-2 text-secondary" style="font-weight: 600">
									{{ $t("receipt.ref.value") }}
								</span>
								<span class="font-18 mb-2" style="font-weight: 900; text-align: left">
									{{ dataPage.reference }}
								</span>
							</div>
							<!--******************-->
							<!--******************-->
							<!-- ZINO CIGARES EL. -->
							<!--******************-->
							<!--******************-->


							<!--******************-->
							<!--******************-->
							<!-- CONVERSATION STAN -->
							<!--******************-->
							<!--******************-->
							<div style="border-top: 1px dashed; display: flex; flex-direction: column; border-bottom: 1px dashed; align-items: flex-start; line-height: 1.4rem;"
								class="mt-2" v-if="dataPage.merchantID == '25010'">
								<span class="font-13 mt-2 text-secondary" style="font-weight: 600">
									{{ $t("receipt.client.value") }}
								</span>

								<span class="font-18 mb-2" style="font-weight: 900">
									{{ dataPage.client }}
								</span>

								<span class="font-13 mt-2 text-secondary" style="font-weight: 600"
									v-if="dataPage.contactClient !== '' && dataPage.contactClient !== 'Numero inconnu'">
									{{ $t("receipt.contact.value") }}
								</span>

								<span class="font-18 mb-2" style="font-weight: 900; text-align: left"
									v-if="dataPage.contactClient !== '' && dataPage.contactClient !== 'Numero inconnu'">
									{{ dataPage.contactClient }}
									<br />
									<div class="font-14 mt-2" style="font-weight: 500; text-align: left"
										v-if="nombre_transactions !== ''">
										<img :src="require(`../assets/img/badges/${imgStatus}`)" width="20"
											style="margin-top: -5px" />
										Client APaym {{ statusClient }}
									</div>
								</span>

								<span class="font-13 mt-2 text-secondary" style="font-weight: 600">
									{{ $t("text_form.email.value") }}
								</span>
								<span class="font-18 mb-2" style="font-weight: 900; text-align: left">
									{{ dataPage.email }}
								</span>

								<span class="font-13 mt-2 text-secondary" style="font-weight: 600">
									{{ $t("receipt.method.value") }}
								</span>
								<span class="font-18 mb-2" style="font-weight: 900; text-align: left">
									{{ dataPage.mode_pay }}
								</span>

								<span class="font-13 mt-2 text-secondary" style="font-weight: 600">
									{{ $t("receipt.amount.value") }}
								</span>
								<h2 class="mb-2" style="font-weight: 900; margin: 0; text-align: left">
									{{
										new Intl.NumberFormat("fr-FR", {
											style: "currency",
											currency: "EUR",
										}).format(500)
									}}
								</h2>


								<span class="font-13 mt-2 text-secondary" style="font-weight: 600">
									{{ $t("receipt.ref.value") }}
								</span>
								<span class="font-18 mb-2" style="font-weight: 900; text-align: left">
									{{ dataPage.reference }}
								</span>
							</div>
							<!--******************-->
							<!--******************-->
							<!-- CONVERSATION STAN -->
							<!--******************-->
							<!--******************-->


							<!--************-->
							<!--************-->
							<!-- Les autres -->
							<!--************-->
							<!--************-->
							<div style="
                                    border-top: 1px dashed;
                                    display: flex;
                                    flex-direction: column;
                                    border-bottom: 1px dashed;
                                    align-items: flex-start;
                                    line-height: 1.4rem;
                                " class="mt-2" v-if="dataPage.isDeleg !== '4' && dataPage.isDeleg !== '5' && dataPage.isDeleg !== '6' &&
                                	dataPage.merchantID !== '24111' && dataPage.merchantID !== '24714' && dataPage.merchantID !== '25010'
                                	">
								<!-- PDCI - Delegation -->
								<div v-if="dataPage.deleg_pdci == '1'" style="
                                        display: flex;
                                        flex-direction: column;
                                        align-items: flex-start;
                                    ">
									<span class="font-13 mt-2 text-secondary" style="font-weight: 600">
										{{ $t("receipt.delegation.value") }}
									</span>
									<span class="font-18 mb-2" style="font-weight: 900">
										{{ dataPage.societe }}
									</span>
								</div>
								<!-- PDCI - Delegation -->

								<!-- Autres - Societe -->
								<div v-if="(dataPage.isDeleg == '0' ||
									dataPage.umed == 1 ||
									dataPage.yango == 1) && dataPage.merchantID !== 14040
									" style=" display: flex; flex-direction: column; align-items: flex-start; ">
									<span v-if="dataPage.societe" class="font-13 mt-2 text-secondary"
										style="font-weight: 600">
										{{ $t("receipt.society.value") }}
									</span>
									<span v-if="dataPage.societe" class="font-18 mb-2" style="font-weight: 900">
										{{ dataPage.societe }}
									</span>
								</div>
								<!-- Autres - Societe -->

								<!-- Organisation locale JCI -->
								<div v-if="dataPage.merchantID === 14040"
									style=" display: flex; flex-direction: column; align-items: flex-start; ">
									<span v-if="dataPage.societe" class="font-13 mt-2 text-secondary"
										style="font-weight: 600">
										{{ $i18n.locale == "fr" ? "Organisation locale" : "Local organisation" }}
									</span>
									<span v-if="dataPage.societe" class="font-18 mb-2" style="font-weight: 900">
										{{ dataPage.societe }}
									</span>
								</div>
								<!-- Organisation locale JCI -->```

								<span v-if="dataPage.isDeleg == '0' || dataPage.yango == 1"
									class="font-13 mt-2 text-secondary" style="font-weight: 600">
									{{ $t("receipt.client.value") }}
								</span>

								<span v-if="dataPage.umed == 1" class="font-13 mt-2 text-secondary"
									style="font-weight: 600">
									{{ $t("receipt.patient.value") }}
								</span>

								<span v-if="dataPage.deleg_pdci == '1'" class="font-13 mt-2 text-secondary"
									style="font-weight: 600">
									{{ $t("receipt.activist.value") }}
								</span>

								<span class="font-18 mb-2" style="font-weight: 900">
									{{ dataPage.client }}
								</span>

								<span v-if="dataPage.isDeleg !== '3' &&
									dataPage.contactClient !== 'Numero inconnu'
									" class="font-13 mt-2 text-secondary" style="font-weight: 600">
									{{ $t("receipt.contact.value") }}
								</span>

								<span v-if="dataPage.yango == 1 &&
									dataPage.contactClient !== 'Numero inconnu'
									" class="font-13 mt-2 text-secondary" style="font-weight: 600">
									{{ $t("text_form.number_driver.value") }}
								</span>

								<span class="font-18 mb-2" style="font-weight: 900; text-align: left"
									v-if="dataPage.contactClient !== 'Numero inconnu'">
									{{ dataPage.contactClient }}
									<br />
									<div class="font-14 mt-2" style="font-weight: 500; text-align: left"
										v-if="nombre_transactions !== ''">
										<img :src="require(`../assets/img/badges/${imgStatus}`)" width="20"
											style="margin-top: -5px" />
										Client APaym {{ statusClient }}
									</div>
								</span>

								<span v-if="dataPage.contactClient2 &&
									dataPage.contactClient2 !== 'Numero inconnu'
									" class="font-13 mt-2 text-secondary" style="font-weight: 600">
									{{ $t("receipt.contact.value") }}
								</span>

								<span class="font-18 mb-2" style="font-weight: 900; text-align: left"
									v-if="dataPage.contactClient2 !== 'Numero inconnu'">
									{{ dataPage.contactClient2 }}
								</span>

								<span class="font-13 mt-2 text-secondary" style="font-weight: 600">
									{{ $t("receipt.method.value") }}
								</span>
								<span class="font-18 mb-2" style="font-weight: 900; text-align: left">
									{{ dataPage.mode_pay }}
								</span>

								<span v-if="dataPage.quantite && dataPage.merchantID === '24823'"
									class="font-13 mt-2 text-secondary" style="font-weight: 600">
									{{ $t("text_form.nombre_ticket.value") }}
								</span>
								<span v-if="dataPage.quantite && dataPage.merchantID === '24823'" class="font-18 mb-2"
									style="font-weight: 900; text-align: left">
									{{
										dataPage.quantite
									}} {{
	$i18n.locale == 'fr' ? (parseInt(dataPage.quantite) > 1 ? 'tickets' : 'ticket') :
	(parseInt(dataPage.quantite) > 1 ? 'tickets' : 'ticket')
}}
								</span>


								<span class="font-13 mt-2 text-secondary" style="font-weight: 600">
									{{ $t("receipt.amount.value") }}
								</span>
								<h2 class="mb-2" style="font-weight: 900; margin: 0; text-align: left">
									{{
										new Intl.NumberFormat("fr-FR", {
											style: "currency",
											currency: "XOF",
										}).format(dataPage.amount)
									}}
								</h2>

								<span v-if="dataPage.feesPaidBy" class="font-13 text-secondary" style="font-weight: 600">
									{{ $t("text_fees.value") }}
								</span>
								<span v-if="dataPage.feesPaidBy" class="font-18 mb-2"
									style="font-weight: 900; text-align: left">
									{{
										new Intl.NumberFormat("fr-FR", {
											style: "currency",
											currency: "XOF",
										}).format(this.fees)
									}}
								</span>

								<!-- Numero de compte NSIA FINANCE --->
								<span v-if="dataPage.numero_compte && dataPage.merchantID !== '24821'"
									class="font-13 mt-2 text-secondary" style="font-weight: 600">
									{{ $t("text_form.account_number.value") }}
								</span>
								<span v-if="dataPage.numero_compte && dataPage.merchantID !== '24821'" class="font-18 mb-2"
									style="font-weight: 900; text-align: left">
									{{ dataPage.numero_compte }}
								</span>
								<!-- Numero de compte NSIA FINANCE --->

								<!-- Numero de compte BOAK --->
								<span v-if="dataPage.numero_compte && dataPage.merchantID === '24821'"
									class="font-13 mt-2 text-secondary" style="font-weight: 600">
									{{ $t("text_form.account_number2.value") }}
								</span>
								<span v-if="dataPage.numero_compte && dataPage.merchantID === '24821'" class="font-18 mb-2"
									style="font-weight: 900; text-align: left">
									{{ dataPage.numero_compte }}
								</span>
								<!-- Numero de compte BOAK --->

								<span v-if="dataPage.societe_en_bourse" class="font-13 mt-2 text-secondary"
									style="font-weight: 600">
									{{ $t("receipt.desired_order.value") }}
								</span>
								<span v-if="dataPage.societe_en_bourse" class="font-18 mb-2"
									style="font-weight: 900; text-align: left">
									{{
										dataPage.nombre_actions
										? dataPage.nombre_actions + " x"
										: ""
									}}
									{{ dataPage.societe_en_bourse }}
								</span>

								<span v-if="dataPage.immatriculation" class="font-13 mt-2 text-secondary"
									style="font-weight: 600">
									{{ $t("receipt.registration.value") }}
								</span>
								<span v-if="dataPage.immatriculation" class="font-18 mb-2"
									style="font-weight: 900; text-align: left">
									{{ dataPage.immatriculation }}
								</span>

								<span v-if="dataPage.service" class="font-13 mt-2 text-secondary" style="font-weight: 600">
									{{ $t("receipt.service.value") }}
								</span>
								<span v-if="dataPage.service" class="font-18 mb-2"
									style="font-weight: 900; text-align: left">
									{{ dataPage.service }}
								</span>

								<span v-if="dataPage.motif && !dataPage.service" class="font-13 mt-2 text-secondary"
									style="font-weight: 600">
									{{ $t("receipt.reason.value") }}
								</span>
								<span v-if="dataPage.motif && !dataPage.service" class="font-18 mb-2"
									style="font-weight: 900; text-align: left">
									{{ dataPage.motif }}
								</span>


								<span v-if="dataPage.grade && dataPage.merchantID === 14040"
									class="font-13 mt-2 text-secondary" style="font-weight: 600">
									{{ $i18n.locale == "fr" ? "Fonction JCI" : "JCI occupation" }}
								</span>
								<span v-if="dataPage.grade" class="font-18 mb-2" style="font-weight: 900; text-align: left">
									{{ dataPage.grade }}
								</span>

								<span class="font-13 mt-2 text-secondary" style="font-weight: 600">
									{{ $t("receipt.ref.value") }}
								</span>
								<span class="font-18 mb-2" style="font-weight: 900; text-align: left">
									{{ dataPage.reference }}
								</span>
							</div>
							<!--************-->
							<!--************-->
							<!-- Les autres -->
							<!--************-->
							<!--************-->

							<div style="border-bottom: 1px dashed" class="mt-2">
								<h2 style="font-weight: 900; margin-top: 10px">
									{{ $t("receipt.thank.value") }}
								</h2>
							</div>

							<div class="font-14 mt-2">
								{{ $t("receipt.authenticity_receipt.value") }}
							</div>

							<div class="mt-2">
								<img :src="dataPage.qrCode" alt="qrCode Uri" width="100" />
							</div>
						</div>
					</div>

					<img src="../assets/recu.png" alt="Logo zigzag" style="transform: rotate(0deg); margin-top: -16px" />

					<div class="element-wrap element-wrap-for-submit mt-5 mb-5 container">
						<button class="btn btn-dark btn-block" @click="exportToPDF">
							{{ $t("receipt.print.value") }}
						</button>

						<a :href="'https://pay.apaym.com/' + dataPage.slug" class="btn btn-link btn-block"
							style="font-weight: 800; color: #0e2abd">{{ $t("receipt.button.value") }}</a>
					</div>
				</div>
			</div>
		</div>
		<!-- Form Receipt -->
	</div>
</template>


<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import axios from "axios";
import { apiUrl } from "../constants/config";
import html2pdf from "html2pdf.js";
import traded_company_list from "../constants/societe-en-bourse.json";

export default {
	components: {
		Loading,
	},
	data() {
		return {
			url_data: {
				ref: "",
			},
			hideReceipt: true,
			isLoading: false,
			fullPage: true,
			dataPage: {
				merchantID: "",
				amount: "",
				fees: "",
				client: "",
				businessLogo: "",
				contactClient: "",
				contactClient2: "",
				email: "",
				merchant: "",
				verified: "",
				rcc: "",
				type: "",
				motif: "",
				date: "",
				mode_pay: "",
				reference: "",
				qrCode: "",
				slug: "",
				nom: "",
				societe: "",
				isDeleg: "",
				grade: "",
				pullCard: "",
				numero_compte: "",
				societe_en_bourse: "",
				nombre_actions: "",
				immatriculation: "",
				service: "",

				air_france: 0,
				baie_sirenes: 0,
				damac: 0,
				yango: 0,
				umed: 0,

				numero_quittance: "",
				numero_police: "",

				feesPaidBy: "",
				customerFee: "",
				merchantFee: "",

				quantite: "",
				adresse: "",
				vip: "",
				numeroTirage: "",
				nbreChances: ""
			},
			langs: ["fr", "en", "mal"],
			hideSharing: true,
			nombre_transactions: 0,
			statusClient: "",
			imgStatus: "bronze.png",
			is_platinum_card: "",

			fees: "",
		};
	},
	computed: {},
	methods: {
		onCancel() {
			console.log("Loader dismiss");
		},

		exportToPDF() {
			html2pdf(this.$refs.document, {
				margin: 1,
				filename: "reçu-" + this.url_data.ref + ".pdf",
				image: { type: "jpeg", quality: 0.98 },
				html2canvas: { dpi: 192, letterRendering: true },
				jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
			});
		},

		showReceipt(ref) {
			this.isLoading = true;
			let dataSend = new FormData();
			dataSend.append("reference", ref + "#");

			axios
				.post(apiUrl + "api/paiement-marchand/recu-paiement", dataSend)
				.then((response) => {
					this.isLoading = false;

					if (response.data.code !== 1) {
						localStorage.clear();
						sessionStorage.clear();
						window.location.href = "https://pay.apaym.com";
						return false;
					}

					if (response.data.reference !== this.url_data.ref + "#".trim()) {
						localStorage.clear();
						sessionStorage.clear();
						window.location.href = "https://pay.apaym.com";
						return false;
					}

					if (
						navigator.userAgent.toLowerCase().indexOf("iphone") > -1 ||
						navigator.userAgent.toLowerCase().indexOf("android") > -1
					) {
						// console.log(navigator.userAgent.toLowerCase())
						this.hideSharing = false;
					}

					// Get infos
					if (response.data.pay_fees === "2") {
						this.dataPage.amount = response.data.amount - response.data.fees;
						this.dataPage.fees = response.data.fees;
					} else {
						this.dataPage.amount = parseFloat(response.data.amount);
						this.dataPage.fees = 0;
					}

					// if ( response.data.pay_fees == "1" )
					// {
					//     let frais = ( (100 * response.data.fees) / response.data.amount), montant = 0 ;

					//     montant = parseInt(response.data.amount) + parseInt( response.data.amount * (frais/100) ) ;
					//     console.log(montant)
					// }

					if (response.data.societe_cote_en_bourse) {
						let symbol = traded_company_list.find(
							(option) =>
								option.company === response.data.societe_cote_en_bourse
						);
						this.dataPage.societe_en_bourse = symbol.symbol; // response.data.societe_cote_en_bourse ;
					}

					console.log(response.data.merchant)

					// this.dataPage.amount = response.data.pay_fees == "2" ? response.data.amount - response.data.fees : response.data.amount ;
					this.dataPage.numero_compte = response.data.numero_compte;
					this.dataPage.nombre_actions = parseInt(response.data.nombre_actions);
					this.dataPage.merchant = response.data.business;
					this.dataPage.contactClient = response.data.numero_consumer;
					this.dataPage.contactClient2 = response.data.numero_consumer2;
					this.dataPage.client = response.data.consumer_name;
					this.dataPage.motif = response.data.motif;
					this.dataPage.merchantID = String(response.data.merchant);
					this.dataPage.mode_pay = response.data.mode_pay;
					this.dataPage.date = response.data.date;
					this.dataPage.rcc = response.data.rcc;
					this.dataPage.fees = response.data.fees;
					this.dataPage.type = response.data.type;
					this.dataPage.nom = response.data.nom;
					this.dataPage.societe =
						response.data.society_name !== null
							? response.data.society_name
							: "";
					this.dataPage.reference = response.data.reference;
					this.dataPage.qrCode = response.data.qrCodeUri;
					this.dataPage.slug = response.data.slug;
					this.dataPage.isDeleg = response.data.isDeleg;
					this.dataPage.grade = response.data.grade;

					this.dataPage.deleg_pdci = response.data.deleg_pdci;
					this.dataPage.yango = response.data.yango;
					this.dataPage.air_france = response.data.air_france;
					this.dataPage.damac = response.data.damac;
					this.dataPage.baie_sirenes = response.data.baie_sirenes;
					this.dataPage.umed = response.data.umed;

					this.dataPage.immatriculation = response.data.immatriculation;
					this.dataPage.service = response.data.service;
					this.dataPage.pullCard = response.data.pull_card
						? response.data.pull_card
						: "";
					this.dataPage.businessLogo =
						response.data.business_logo !== ""
							? "https://apaym.com/cms/images/logo_marchand/" +
							response.data.business_logo
							: "";

					this.dataPage.numero_police = response.data.numero_police;
					this.dataPage.numero_quittance = response.data.numero_quittance;

					this.dataPage.feesPaidBy = response.data.feesPaidBy;
					this.dataPage.customerFee = response.data.customerFee;
					this.dataPage.merchantFee = response.data.merchantFee;

					this.dataPage.quantite = response.data.quantite;
					this.dataPage.adresse = response.data.adresse;
					this.dataPage.vip = response.data.vip;
					this.dataPage.email = response.data.email;


					this.dataPage.numeroTirage = response.data.numeroTirage;
					this.dataPage.nbreChances = response.data.nbreChances;

					// Nombre de transactions pour connaitre le statut du client
					// BLEU (1 - 4), BRONZE (5 - 9), SILVER (10 - 19), GOLD (20 - 29), PLATINUM (30 - Infini)
					this.nombre_transactions = response.data["nombre_transactions"];
					this.is_platinum_card = response.data["is_platinum_card"];

					// console.log(this.dataPage.feesPaidBy);

					if (this.nombre_transactions === "") {
						this.nombre_transactions = ""; // Le client n'est pas un client APaym
					} else {
						if (this.nombre_transactions <= 20) {
							this.statusClient = "Bronze";
							this.imgStatus = "bronze.png";
						} else if (this.nombre_transactions <= 40) {
							this.statusClient = "Silver";
							this.imgStatus = "silver.png";
						} else if (this.nombre_transactions <= 65) {
							this.statusClient = "Gold";
							this.imgStatus = "gold.png";
						} else if (this.nombre_transactions <= 90) {
							this.statusClient = "Platinum";
							this.imgStatus = "platinum.png";
						} else if (this.nombre_transactions <= 130) {
							this.statusClient = "Diamond";
							this.imgStatus = "diamond.png";
						} else {
							this.statusClient = "Infinite";
							this.imgStatus = "infinite.png";
						}

						if (
							this.is_platinum_card === "1" &&
							this.nombre_transactions <= 90
						) {
							this.statusClient = "Platinum";
							this.imgStatus = "platinum.png";
						}
					}

					switch (this.dataPage.feesPaidBy) {
						case "C_PAY_FEE":
							this.fees = this.dataPage.customerFee;
							break;

						case "M_PAY_FEE":
							this.fees = this.dataPage.merchantFee;
							break;

						case "C_M_PAY_FEE":
							this.fees = this.dataPage.merchantFee;
							break;
					}

					window.localStorage.setItem("mode_pay", response.data.mode_pay);
					this.paymentMethod(response.data.mode_pay);

					// Show receipt bloc
					this.hideReceipt = false;
				})
				.catch((error) => {
					this.isLoading = false;
					this.$swal("Un erreur est survenue !", error.response.data, "error");
				});
		},

		paymentMethod(val) {
			switch (val) {
				case "QR":
					this.dataPage.mode_pay = "Visa QR";
					break;
				case "BICICI":
					this.dataPage.mode_pay =
						this.$t("payment_modal.bicici.value") +
						" ••••" +
						this.dataPage.pullCard;
					break;
				case "UBA":
					this.dataPage.mode_pay =
						this.$t("payment_modal.bicici.value") +
						" ••••" +
						this.dataPage.pullCard;
					break;
				case "Orabank - VISA":
					this.dataPage.mode_pay =
						this.$t("payment_modal.bicici.value") +
						" ••••" +
						this.dataPage.pullCard;
					break;
				case "Orabank - MASTERCARD":
					this.dataPage.mode_pay =
						this.$t("payment_modal.bicici.value") +
						" ••••" +
						this.dataPage.pullCard;
					break;
				default:
					this.dataPage.mode_pay = val;
					break;
			}
		},

		changeLanguage(event) {
			window.localStorage.setItem("lang", event.target.value);
			this.paymentMethod(window.localStorage.getItem("mode_pay"));
		},
	},
	mounted() {
		this.url_data.ref = this.$route.params.ref;

		if (!this.url_data.ref) {
			// localStorage.clear() ;
			// sessionStorage.clear() ;
			// window.location.href = "https://pay.apaym.com" ;
			return false;
		}

		this.showReceipt(this.url_data.ref);
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
